import React, { Component } from "react"
import SEO from "components/seo"
import constants from "../constants"

class ComingSoonPage extends Component {
  render() {
    return (
      <div>
        <SEO
          title={`Coming Soon to Ecco`}
          pathname={this.props.location.pathname}
        />
        <div className={`w-screen h-screen fixed lg:inline`}>
          <div className={`flex flex-col justify-top lg:justify-center w-full h-full bg-yellow-light`}>
            <div className={`mx-auto w-7/12 lg:w-3/12 text-center transform translate-y-1/2 lg:transform-none`}>
              <p className={`text-black ${constants.paragraph}`}>The countdown is on <br/>Discover</p>
              <img alt={`ecco black logo`} className={`w-full mt-5 mb-8 lg:mt-8 lg:mb-16`} src={`/logo_black.svg`} />
              <p className={`text-black ${constants.paragraph}`}>January 28th & 29th, 2021</p>
            </div>
          </div>
          <footer className="pb-2 lg:pb-0 bg-yellow-light lg:bg-transparent fixed z-10 bottom-4 lg:right-4 w-full lg:w-auto text-xs text-center">
            <div className={`inline-block opacity-40`}>
              <div className="flex">
                <div className="mr-4 flex-shrink-0 self-center">
                  <img
                    className=""
                    src="/logo_black_single.svg"
                    alt="Ecco Copyright"
                  />
                </div>
                <div>
                  <p className="">&copy; Ecco 2021. All Rights Reserved.</p>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    )
  }
}

export default ComingSoonPage
